<template>

  <section id="filter-survey">
    
    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component"/>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h3 class="section-head-title my-3">
          <i class="fa-regular fa-square-check color-secondary"></i>
          Crea o edita tu encuesta
        </h3>
      </div>
    </div>

    <div class="row mt-3 mt-xxl-0">
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0 input-search">
            <input 
                type="text" 
                class="form-control input-custom"
                placeholder="Buscar encuesta"
                v-model="buscar_texto"
                @keyup.enter="filterText()"/>
                <span class="input-search-icon">
                  <font-awesome-icon icon="search"/>
                </span>
          </div>
          <div class="col">
            <v-select 
              class="selvue-custom"
              :options="estados" 
              v-model="sel_estado"
              label="nombre_categoria"
              :clearable="false"
              placeholder="Filtrar por estado">
            </v-select>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
            <font-awesome-icon 
                icon="filter" 
                class="color-secondary pe-1"
              />
              Filtrar
          </div>
          <div class="col-12 col-lg-9 col-xl-8">
            <div class="row" v-click-outside="handleCalendar">
              <div class="col-6">
                <div 
                  class="form-control input-calendar" 
                  @click="openCalendar('start-date')">
                  <span>{{ dateFormatymd(init_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    @click="deleteDate()"
                    v-show="init_date != 'Inicio'"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div 
                  class="form-control input-calendar disabled" 
                  v-if="init_date == 'Inicio'">
                  <span>{{ end_date }}</span>
    
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
                <div
                  class="form-control input-calendar"
                  v-if="init_date != 'Inicio'"
                  @click="openCalendar('end-date')">
                  <span>{{ dateFormatymd(end_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    @click="deleteDate()"
                    v-show="end_date != 'Término'"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-12 position-relative">
                <div 
                  class="input-calendar-dropdown" 
                  :class="calendar_side" 
                  v-if="show_calendar">
                  <v-calendar
                    color="blue"
                    :max-date="max_date"
                    :min-date="min_date"
                    :attributes="attributes"
                    @dayclick="onDayClick"
                  />
                  <div 
                    class="border-arrow" 
                    :class="calendar_side">
                    <div class="arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ListTable v-if="filtro != true" :encuestas="encuestas_list" :paginas_encuestas="paginas_encuestas" :estados="estados"/>
        <ListTable v-else :encuestas="encuestas"/>
        <!--fin tabla-->
        <Spinner v-if="show_spinner"/>
      </div>
    </div>
  </section>

</template>
<script>
import SectionTabs from "../Section/SectionTabs.vue";
import ListTable from "./ListTable.vue";
import { mapActions, mapState } from "vuex";
import Spinner from "../Spinner.vue";
export default {
  components: { SectionTabs, ListTable, Spinner },
  data() {
    return {
      show_spinner: false,
      tab_component: [
        {
          name: "Gestiona",
          route: "encuestas-list",
        },
        {
          name: "Crear",
          route: "encuestas-add",
        },
      ],
      // Filtros
      filtro: "",
      encuestas: [], //alertas
      // Filtro texto
      buscar_texto: "",
      // Filtro Estado
      cat_selected: "Filtrar categoría",
      open_select: false,
      encuestas_status: ["Todas", "Activa", "Programada", "Finalizada"],
      id_categoria: "0",
      // Filtro periodo
      img_calendar: require("@/assets/img/utilities/calendar.svg"),
      init_date: "Inicio",
      end_date: "Término",
      // Calendario
      calendar_side: "",
      show_calendar: false,
      min_date: "",
      max_date: "",
      start_calendar: false, //inicio
      date_start: "",
      end_calendar: false, //termino
      date_end: "",

      filtro_texto: false,
      filtro_estado: false,
      filtro_periodo: false,
      texto_encuestas: [], // texto_alertas: [],
      estado_encuestas: [], // estado_alertas: [],
      periodo_encuestas: [], // periodo_alertas_todas: [],
      pagina_default: "1",
      //FF
      // estados: [
      //   { id: 0, label: "Todas" },
      //   { id: 1, label: "Activa" },
      //   { id: 2, label: "Programada" },
      //   { id: 3, label: "Finalizada" }
      // ],
      sel_estado: "Filtrar por estado",
      selected_estado: "0"
    };
  },
  computed: {
    ...mapState("encuestasModule", ["encuestas_list", "paginas_encuestas", "estados"]),
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
  },

  async created() {
    this.getEstadosEncuestas();
    this.getEncuestas();
    
    //console.log("list: ", this.encuestas_list);
    // // console.log("Ruta add: ", this.$route);
  },
  mounted() {
    localStorage.removeItem("encuestaStore");
    localStorage.removeItem("preguntas_deleted");
  },
  methods: {
    ...mapActions("encuestasModule", ["getEncuestasStore", "getPaginasEncuestas", "getEncuestasList", "getEstadosEncuestas"]),
    // Obtener lista de encuestas
    async getEncuestas(){
      this.show_spinner = true;
      let data_ = {
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        nombre: this.buscar_texto,
        fecha_inicio: this.date_start,
        fecha_fin: this.date_end,
        id_categoria: this.selected_estado,
        limite: "20"
      };
      await this.getPaginasEncuestas(data_).then(res=>{
        if(res){
          res.forEach((data) => {
            if (data.pagina == this.pagina_default) 
            {
              //this.ids_solicitudes = data.notificaciones;
              let variables = {
                id_empresa: this.$ls.get("user").empresa[0].id_empresa,
                id_encuesta: data.reportes
              }
              this.getEncuestasList(variables);
            }
          });
        }
      });
      this.show_spinner = false;
    },
    // Filtro text
    filterText() {
      this.initializeFilter();
      this.texto_encuestas = [];
      console.log("Estoy acá");
      this.getEncuestas();
      // busca por texto
      // if (this.buscar_texto != 0) {
      //   this.texto_encuestas = this.encuestas_list.filter((e) =>
      //     e.nombre_encuesta
      //       .toLowerCase()
      //       .includes(this.buscar_texto.toLowerCase())
      //   );
      //   this.filtro_texto = true;
      //   this.unifiedFilter();
      // } else {
      //   this.texto_encuestas = this.encuestas_list;
      //   this.filtro_texto = false;
      //   this.unifiedFilter();
      // }
    },
    // Filtro estado
    toggleSelect() {
      if (this.open_select) {
        this.open_select = false;
      } else {
        this.open_select = true;
      }
    },
    filterStatus(status) {
      this.initializeFilter();
      this.estado_encuestas = [];
      if (status == "Activa") this.id_categoria = "1";
      if (status == "Programada") this.id_categoria = "2";
      if (status == "Finalizada") this.id_categoria = "3";
      if (status == "Todas") this.id_categoria = "0";
      this.cat_selected = this.id_categoria;

      // busca por estado
      if (status != "Todas") {
        this.encuestas_list.forEach((e) => {
          if (this.nameState(e) == status) {
            this.estado_encuestas.push(e);
          }
        });
        this.filtro_estado = true;
        this.unifiedFilter();
      }
      if (status == "Todas") {
        this.filtro_estado = false;
        this.estado_encuestas = this.encuestas_list;
        this.unifiedFilter();
      }
      setTimeout(() => {
        this.open_select = false;
      }, 100);
    },
    nameState(encuesta) {
      if (encuesta.estado == "Activa") return "Activa";
      if (encuesta.estado == "Programada") return "Programada";
      if (encuesta.estado == "Cerrada") return "Finalizada";
    },
    // Filtro periodo
    handleCalendar() {
      this.show_calendar = false;
    },
    deleteDate() {
      console.log("show-calendar", this.show_calendar);
      console.log("calendar-side", this.calendar_side);
      if (this.calendar_side == "survey-list__end-date") {
        this.end_calendar = false;
        this.date_end = "";
        this.end_date = "Término";
        this.filterTime(this.date_start, this.date_end);
        this.show_calendar = false;
      } else {
        (this.calendar_side = ""), (this.min_date = "");
        this.max_date = "";
        this.init_date = "Inicio";
        this.date_start = "";
        (this.start_calendar = false), (this.end_calendar = false);
        this.date_end = "";
        this.end_date = "Término";
        // this.filtro = false;
        this.filtro_periodo = false;
        this.periodo_encuestas = this.encuestas_list;
        this.unifiedFilter();
        this.show_calendar = false;
      }
      console.log("show-calendar2", this.show_calendar);
      this.show_calendar = false;
      this.getEncuestas();
    },
    openCalendar(side) {
      this.show_calendar = true;
      console.log("show-calendar3", this.show_calendar);
      this.calendar_side = side;
      if (this.calendar_side == "start-date") {
        this.min_date = "";
        if (this.end_calendar == true) {
          this.max_date = this.date_end;
        }
      }
      if (this.calendar_side == "end-date") {
        this.max_date = "";
        if (this.start_calendar == true) {
          this.min_date = this.date_start;
        }
      }
    },
    onDayClick(day) {
      if (this.calendar_side == "start-date") {
        this.start_calendar = true;
        this.date_start = this.get_date_start(day.id);
        this.init_date = day.id;
      }
      if (this.calendar_side == "end-date") {
        this.end_calendar = true;
        this.date_end = this.get_date_end(day.id);
        this.end_date = day.id;
      }
      // console.log("init: "+ this.date_start+", end: "+ this.date_end);
      this.filterTime(this.date_start, this.date_end);
      this.show_calendar = false;
    },
    get_date_start(day) {
      let dia = day.toString() + "T00:00:00";
      return dia;
    },
    get_date_end(day) {
      let dia = day.toString() + "T23:59:59";
      return dia;
    },
    filterTime(inicio, fin) {
      console.log("fechas: ", fin);
      if(this.date_end!="" && this.date_start!=""){
        this.getEncuestas();
      }
      // this.initializeFilter();
      // let init = new Date(inicio);
      // let end = new Date(fin);
      // this.periodo_encuestas = [];
      // if (inicio != "" && fin == "") {
      //   // busca por inicio
      //   this.encuestas_list.forEach((e) => {
      //     let termino = new Date(e.fecha_termino_encuesta);
      //     if (termino.getTime() >= init.getTime())
      //       this.periodo_encuestas.push(e);
      //   });
      //   this.filtro_periodo = true;
      //   this.unifiedFilter();
      // } else {
      //   // buscar inicio fin
      //   this.encuestas_list.forEach((e) => {
      //     let termino = new Date(e.fecha_termino_encuesta);
      //     if (
      //       termino.getTime() >= init.getTime() &&
      //       termino.getTime() <= end.getTime()
      //     ) {
      //       this.periodo_encuestas.push(e);
      //     }
      //   });
      //   this.filtro_periodo = true;
      //   this.unifiedFilter();
      // }
    },
    initializeFilter() {
      this.filtro = true;
      if (
        this.filtro_texto == false &&
        this.filtro_estado == false &&
        this.filtro_periodo == false
      ) {
        this.encuestas = this.encuestas_list;
        this.texto_encuestas = this.encuestas_list;
        this.estado_encuestas = this.encuestas_list;
        this.periodo_encuestas = this.encuestas_list;
      }
    },
    unifiedFilter() {
      // agregar texto
      let texInclude = this.encuestas_list.filter((e) =>
        this.texto_encuestas.includes(e)
      );
      // agregar estado
      let stateInclude = texInclude.filter((e) =>
        this.estado_encuestas.includes(e)
      );
      // agregar periodo
      let timeInclude = stateInclude.filter((e) =>
        this.periodo_encuestas.includes(e)
      );

      this.encuestas = timeInclude;
    },
  },
  watch: {
    sel_estado(value) {
      if(Object.keys(value).length === 0){
        this.selected_estado = "0";
      }
      else{
        this.selected_estado = value.id_categoria;
      }
      //console.log(this.selected_estado);
      this.getEncuestas();
    }
  }
};
</script>
