<template>

<div id="table-survey">
    <div class="row">
      <div class="col-12 mt-3">
        <div class="table-container">
          <table class="table table-custom table-borderless table-custom-responsive mb-0">
            <thead>
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Estado</th>
                <th scope="col">Segmentación</th>
                <th scope="col">Participación</th>
                <th scope="col">Fecha de termino</th>
                <th scope="col" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(encuesta, i) in encuestas"
                :key="i">
                <td class="th th-nombre">
                  {{ encuesta.nombre_encuesta }} <br>
                  <small class="fst-italic color-light" v-if="encuesta.preguntas.length > 0">
                    <font-awesome-icon icon="circle-question"/>
                    {{ encuesta.preguntas.length }} Preguntas
                  </small>
                </td>
                <td class="th th-estado text-start text-md-center">
                  <span 
                    v-if="encuesta.nombre_categoria == 'Activa'"
                    class="badge badge-custom badge-custom-green-light">
                    <font-awesome-icon icon="check"/>
                    {{ encuesta.nombre_categoria }}
                  </span>
                  <span 
                    v-if="encuesta.nombre_categoria == 'Finalizada'"
                    class="badge badge-custom badge-custom-gray-light">
                    <font-awesome-icon icon="lock"/>
                    {{ encuesta.nombre_categoria }}
                  </span>
                  <span 
                    v-if="encuesta.nombre_categoria == 'Programada'"
                    class="badge badge-custom badge-custom-blue-light">
                    <font-awesome-icon icon="clock"/>
                    {{ encuesta.nombre_categoria }}
                  </span>        
                </td>
                <td 
                  class="th th-segmentacion" 
                  v-if="encuesta.segmentaciones == null">
                  Transversal
                </td>
                <td 
                  class="th th-segmentacion" 
                  v-else-if="encuesta.segmentaciones.length == 0">
                  Transversal
                </td>
                <td 
                  v-else
                  class="th th-segmentacion">
                  {{ nameSeg(encuesta.segmentaciones[0].id_segmentacion) }}
                </td>
                <td 
                  class="th th-participacion text-md-center" 
                  v-if="encuesta.estadisticas != undefined && ( encuesta.preguntas.length > 0)">
                  <div class="progress">
                    <div 
                      :style="`width: ${encuesta.estadisticas.porcentaje}%`"
                      class="progress-bar"
                      :class="`${encuesta.estadisticas.porcentaje}` == '100' ? 'bg-success' : ''">
                      {{ encuesta.estadisticas.porcentaje }}%
                    </div>
                  </div>  
                  <small class="text-muted">
                    <span 
                      class="badge border color-gray" 
                      v-if="encuesta.estadisticas.porcentaje < 20">
                    {{ encuesta.estadisticas.porcentaje }}%
                    </span>
                    {{ encuesta.estadisticas.total_respuestas }}/{{ encuesta.estadisticas.total_usuarios }} usuarios
                  </small>    
                </td>
                <td v-else class="th th-participacion text-md-center">
                  <span class="badge badge-custom-small border color-gray">
                    <font-awesome-icon icon="square-arrow-up-right" class="color-brown"/>
                    Encuesta externa
                  </span>
                </td>
                <td class="th th-fecha-termino">
                  {{ dateFormatymd(encuesta.fecha_termino_encuesta) }}
                </td>
                <td class="th th-accion text-center">
                    <div 
                      class="dropdown dropdown-custom d-flex justify-content-center" 
                      @click="openOption(encuesta.id_encuesta)">
                      <button 
                        class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                        type="button" 
                        data-bs-toggle="dropdown">
                        <font-awesome-icon icon="ellipsis-vertical"/>
                      </button>
                      <ul 
                        class="dropdown-menu"
                        v-click-outside="outOption">
                        <li v-if="encuesta.preguntas.length > 0 && encuesta.nombre_categoria != 'Programada' && encuesta.estadisticas.total_respuestas > 0">
                          <a 
                            class="dropdown-item" 
                            @click="verDetalles(encuesta)"
                            href="javascript:">
                            <font-awesome-icon class="pe-2 color-main" icon="eye"/>
                            Ver detalle
                          </a>
                        </li>
                        <li>
                          <a 
                            @click="editEncuesta(encuesta)"
                            v-if="encuesta.nombre_categoria == 'Programada'"
                            class="dropdown-item" 
                            href="javascript:">
                            <font-awesome-icon class="pe-2 color-brown" icon="pen"/>
                            Editar
                          </a>
                        </li>
                        <li>
                          <a 
                            @click="openQuestionDelete(encuesta)"
                            v-if="encuesta.estadisticas.total_respuestas  == 0 && encuesta.tipo_encuesta.id_tipo_encuesta!=2"
                            class="dropdown-item" 
                            href="javascript:">
                            <font-awesome-icon class="pe-2 color-red" icon="trash-alt"/>
                            Eliminar
                          </a>
                        </li>
                      </ul>
                    </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div 
            v-if="encuestas.length==0"
            class="alert alert-info mb-0 mt-3">
            <font-awesome-icon class="pe-2" icon="circle-info"/>
              No hay resultados para tu búsqueda.
          </div>

          <div class="modal-encuestas" v-if="open_modal">
            <div class="modal-encuestas__detalle py-3 px-5">
              <div class="modal-encuestas__upside-wrapper">
                <h3 class="modal-encuestas__title">{{ detail.titulo }}</h3>
      
                <font-awesome-icon
                  class="modal-encuestas__close-icon"
                  icon="times"
                  @click="open_modal = false"
                ></font-awesome-icon>
              </div>
              <hr />
              <div class="modal-encuestas__graph-container">
                <GraphCard v-for="(data, i) in graph_data" :key="i" :data="data" />
              </div>
              <div class="modal-encuestas__buttons-wrapper">
                <button class="modal-encuestas__download-button">
                  <export-json-excel
                    style="color: #fff"
                    :data="data_excel"
                    :fields="fields_excel"
                    :name="detail.titulo"
                  >
                    Descargar Excel
                  </export-json-excel>
                </button>
                <button
                  @click="open_modal = false"
                  class="modal-encuestas__close-button"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
          <Status
            v-if="open_modal_status"
            :msg="modal_status_msg"
            :status="modal_status"
            @close="acceptStatus"
          />
          <Question
            v-if="open_question_modal"
            :msg="question_modal_msg"
            :hideCancel="false"
            @cancel="cancelQuestion"
            @accept="acceptQuestion"
          />
          <Spinner v-if="show_spinner"/>

        </div>
        <div class="users-list__pagination" v-if="pagination.page_count > 0">
          <paginate
            v-model="pagination.actual_page"
            :page-count="paginas_encuestas.length"
            :page-range="pagination.page_range"
            :click-handler="pagination.click_handler"
            :prev-text="pagination.prev_text"
            :next-text="pagination.next_text"
            :container-class="pagination.container_class"
            :page-class="pagination.page_class"
          />
        </div>
      </div>
    </div>
</div>

</template>

<script>
import Question from "../Modales/Question.vue";
import Status from "../Modales/Status.vue";
//import { DELETE_ENCUESTA } from "@/apollo/mutations";
import GraphCard from "./GraphCard.vue";
import Spinner from "../Spinner.vue";
import { mapActions, mapState } from "vuex";
import "export-json-excel";
export default {
  components: { Question, Status, GraphCard, Spinner },
  props: {
    encuestas: {
      type: Array,
    },
    paginas_encuestas: {
      type: Array,
    },
    estados: {
      type: Array,
    },
  },
  data() {
    return {
      open_modal: false,
      option: "",
      open_question_modal: false,
      question_modal_msg: "",
      encuesta_to_delete: null,
      eliminarEncuestaIdEmpresa: "",
      eliminarEncuestaIdEncuesta: "",
      eliminarEncuestaIdUsuario: "",
      statusEncuesta: "",
      open_modal_status: false,
      show_spinner: false,
      detail: {
        titulo: "",
      },
      graph_data: [],
      fields: [],
      data: [],
      data_excel: [],
      fields_excel: [],
      pagina_actual: "1",
      pagination: {
        actual_page: 1,
        page_count: 1,
        page_range: 3,
        click_handler: this.paginacion,
        prev_text: '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text: '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class:"pagination-item"
      },
    };
  },
  computed: {
    ...mapState("encuestasModule", ["reporte"]),
  },
  methods: {
    ...mapActions("encuestasModule", ["getReporte", "deteleEncuesta", "getEncuestasList"]),
    // control de paginación
    paginacion(){
      //console.log("estoy en la acción ");
      let total = this.paginas_encuestas.length;
      if(this.pagination.actual_page <= total){
        //this.pagina_actual = total;
        
        this.pagina_actual = this.pagination.actual_page;
        //console.log("para llamar al metodo de pagina: ", this.pagina_actual);
        this.getListaEncuestaP();
      }
    },
    async getListaEncuestaP(){
      this.show_spinner = true;
      //console.log("estoy en paginación: ", this.paginas_encuestas);
      this.paginas_encuestas.forEach((data) => {
        //console.log(data.pagina, " y ", this.pagina_actual)
            if (data.pagina == this.pagina_actual) 
            {
              //console.log("estoy en forEach: ", data.pagina);
              let variables = {
                id_empresa: this.$ls.get("user").empresa[0].id_empresa,
                id_encuesta: data.reportes
              }
              this.getEncuestasList(variables);
            }
          });
      this.show_spinner = false;
    },
    //abrir opciones
    openOption(id) {
      setTimeout(() => {
        this.option = id;
      }, 10);
    },
    outOption() {
      if (this.option != "") {
        this.option = "";
      }
    },
    editEncuesta(encuesta) {
      this.$router.push({
        name: "encuestas-edit",
        params: { id: encuesta.id_encuesta },
      });
    },
    openQuestionDelete(encuesta) {
      this.encuesta_to_delete = encuesta;
      this.open_question_modal = true;
      this.question_modal_msg = "¿Estas seguro de eliminar esta encuesta?";
    },
    cancelQuestion() {
      this.open_question_modal = false;
    },
    acceptStatus() {
      this.open_modal_status = false;
      this.modal_status_msg = "";
    },

    async acceptQuestion() {
      //this.show_spinner = true;
      let data_ = {
        eliminarEncuestaIdEncuesta: this.encuesta_to_delete.id_encuesta,
        eliminarEncuestaIdEmpresa: this.$ls.get("user").id_empresa_fk,
        eliminarEncuestaIdUsuario: this.$ls.get("user").id_usuario,
      }
      await this.deteleEncuesta(data_).then(res=>{
        if(res){
          this.$toast.open({
            message: "Tú encuesta ha sido eliminada correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
        }
        else{
          this.$toast.open({
            message: "No se ha podido eliminar la encuesta, intenta nuevamente",
            type: "error",
            duration: 0,
            position: "top-right",
          });
        }
        this.open_question_modal = false;
      })
      .catch((err) => {
          console.log("err", err);
          // this.show_spinner = false;
          this.open_question_modal = false;
          this.$toast.open({
            message: "El proceso de eliminación de encuesta ha fallado, favor contactar a soporte@carpetres.cl",
            type: "error",
            duration: 0,
            position: "top-right",
          });
      });
    },
    async verDetalles(encuesta) {
      this.show_spinner = true;
      this.detail.titulo = encuesta.nombre_encuesta;
      let params = {
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        idEncuesta: encuesta.id_encuesta,
      };
      await this.getReporte(params);
      this.getDataExcel();
      if (this.reporte.preguntas.length != 0) {
        this.show_spinner = false;
        this.fillAnswers(this.reporte);
        this.open_modal = true;
      } else {
        this.show_spinner = false;
        this.modal_status_msg = "No hay preguntas que graficar";
        this.modal_status = false;
        this.open_modal_status = true;
      }
    },

    //
    fillAnswers(data) {
      // arreglo de preguntas
      let questions = this.getQuestions(data);
      // arreglo de respuestas
      let answers = this.getAnswers(data);

      questions = this.getCounteredAnswer(answers, questions);

      // arreglo de emojis
      questions.forEach((e) => {
        if (e.typeP == "Satisfacción") {
          e.textA = '<img style="height: 70px" src=' + e.textA + ">";
        }
      });
      // Pasa los datos graph
      this.data = questions;
      this.graph_data = this.getChartsData(questions);
    },

    // obtiene el arreglo de preguntas (no considera desarrollo)
    getQuestions(data) {
      let questions = [];
      let i = 0;
      data.preguntas.forEach((ques) => {
        ques.alternativas.forEach((alt) => {

          questions.push({
            index: i,
            idP:ques.id_pregunta,
            textP: ques.texto_pregunta,
            typeP: this.getTipo(ques),
            idA: alt.id_alternativa,
            textA: alt.texto_alternativa,
            respuesta: alt.texto_alternativa,
            count: 0,
          });
        });
        // excluye desarrollo
        if (this.getTipo(ques) != "Desarrollo") {
            i++;
          }
      });
      return questions;
    },

    // obtenie un arreglo con las espuestas (no considera desarrollo)
    getAnswers(data) {
      let answers = [];
      data.respuestas.forEach((ans) => {
        ans.respuestas.forEach((alt) => {
          answers.push({
            idP: alt.pregunta.id_pregunta,
            idR: ans.id_respuesta,
            textR: alt.respuesta,
          });
        });
      });
      return answers;
    },
    // obtiene el objeto graph
    getChartsData(data) {
      let graph_data = [];
      let end = data[data.length - 1].index;

      for (let i = 0; i < end + 1; i++) {
        if(this.getResponses(data, i) == 0){
          graph_data.push({
          name_question: this.getName(data, i),
          responses: this.getResponses(data, i),
          series: this.getSeries(data, i),
          
          });
        }else{
          graph_data.push({
          name_question: this.getName(data, i),
          responses: this.getResponses(data, i),
          series: this.getSeries(data, i),
          chartOptions: {
            chart: {
              type: "pie",
              fontFamily: "Product Sans Bold",
              width: "40%",
              dropShadow: {
                enabled: true,
                top: 0,
                left: 0,
                blur: 1,
                color: "#0072f7",
                opacity: 0.35,
              },
            },
            dataLabels: {
              style: {
                colors: ["#fff"],
                fontSize: "17px",
                fontWeight: "bold",
              },
              dropShadow: {
                top: 0,
                left: 0,
                blur: 1,
                color: "#0072f7",
                opacity: 0.45,
              },
            },
            labels: this.getLabel(data, i),
            legend: {
              show: true,
              fontSize: "17px",
              fontFamily: "Product Sans Regular",
              offsetX: 0,
              position: "right",
              labels: {
                colors: ["#999BA6"],
                useSeriesColors: false,
              },
              markers: {
                offsetX: 0,
              },
            },
          }
        });
        }
        
      }
      //console.log("graph_data", graph_data);
      return graph_data;
      },
    // Retorna el nombre de la pregunta asociado a index
    getName(data, i) {
      let name = "";
      data.forEach((e) => {
        if (e.index == i) {
          // console.log("son igual", e.textP);
          name = e.textP;
        }
      });
      return name;
    },

    // retorna cantidad total de respuestas asociado a la pregunta
    getResponses(data, i) {
      let count = 0;
      data.forEach((e) => {
        if (e.index == i) {
          count = count + e.count;
        }
      });
      return count;
    },

    // retorna cantidades de respuestas
    getSeries(data, i) {
      let series = [];
      data.forEach((e) => {
        if (e.index == i) {
          series.push(e.count);
        }
      });
      return series;
    },

    // Retorna texto alternativas
    getLabel(data, i) {
      let labels = [];
      // console.log('LABELS', data);
        data.forEach((e) => {
        if (e.index == i) {
          labels.push(e.textA);
        }
      });
      return labels;
    },
    // Retorna id pregunta
    getIdQuestion(data, i) {
      let id = "";
      data.forEach((e) => {
        if (e.index == i) {
          id = e.idP;
        }
      });
      return id;
    },

    // Retorna el tipo de pregunta
    getTipo(question) {
      // Desarrollo: tipo_pregunta.id_pregunta_tipo = 1
      if (question.tipo_pregunta.id_pregunta_tipo == "1") return "Desarrollo";
      // Alternativa única: tipo_pregunta.id_pregunta_tipo = 2
      if (question.tipo_pregunta.id_pregunta_tipo == "2") return "Alternativa Única";
      // Alternativa Múltiple: tipo_pregunta.id_pregunta_tipo = 3
      if (question.tipo_pregunta.id_pregunta_tipo == "3") return "Alternativa Múltiple";
      // Calificación: tipo_pregunta.id_pregunta_tipo = 4, is_star: true, is_points: false,
      if (
        question.tipo_pregunta.id_pregunta_tipo == "4" &&
        question.is_star == true
      )
        return "Calificación";
      // Escala lineal:  tipo_pregunta.id_pregunta_tipo = 4, is_star: false, is_points: true
      if (
        question.tipo_pregunta.id_pregunta_tipo == "4" &&
        question.is_star == false
      )
        return "Escala";
      // Satisfacción:  tipo_pregunta.id_pregunta_tipo = 5,
      if (question.tipo_pregunta.id_pregunta_tipo == "5") return "Satisfacción";
    },

    pollListVoid() {
      if (this.encuestas.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    // Exporta a excel
    getDataExcel() {
      let fields = []
      let data_ = [];
      let answers = this.getAnswers(this.reporte);
      let questions = this.getQuestions(this.reporte);
      questions = this.getCounteredAnswer(answers,questions);
      if (this.reporte.is_anonima) {
        fields = [
          // { 'title': '#', 'name': 'index', },
          { title: "Pregunta", name: "pregunta" },
          { title: "Tipo Pregunta", name: "tipo_pregunta" },
          { title: "Respuesta", name: "respuesta" },
          { title: "Cantidad de respuestas", name: "count_respuesta" },
        ];
        this.reporte.preguntas.forEach(question => {
          question.alternativas.forEach(alt => {
            let obj_count = questions.find(quest => quest.idA == alt.id_alternativa);
            let data = {
              tipo_pregunta: this.getTipo(question),
              pregunta: question.texto_pregunta,
              respuesta: this.getTipo(question) == "Satisfacción"? this.getNameEmoji(alt.texto_alternativa,):alt.texto_alternativa,
              count_respuesta: obj_count.count
            }
            data_.push(data);
          });
        });
      } else {
        fields = [
          // { 'title': '#', 'name': 'index', },
          { title: "RUT", name: "rut" },
          { title: "Nombre", name: "nombre" },
          { title: "Apellido", name: "apellido" },
          { title: "Cargo", name: "cargo" },
          { title: "Gerencia", name: "gerencia" },
          { title: "Pregunta", name: "pregunta" },
          { title: "Tipo Pregunta", name: "tipo_pregunta" },
          { title: "Respuesta", name: "respuesta" },
        ];
        this.reporte.respuestas.forEach(response => {
          response.respuestas.forEach(user_response => {
            let pregunta_ = this.reporte.preguntas.find(pregunta => pregunta.id_pregunta == user_response.pregunta.id_pregunta);
            let rut_ = response.usuario.rut_usuario + '-' +response.usuario.dv_usuario;
            let poll_data = {
              nombre: response.usuario.primer_nombre,
              apellido: response.usuario.apellido_paterno,
              cargo: response.usuario.cargo[0].nombre_cargo,
              gerencia: response.usuario.gerencia[0].nombre_gerencia,
              tipo_pregunta: this.getTipo(pregunta_),
              pregunta: user_response.pregunta.texto_pregunta,
              respuesta: this.getTipo(pregunta_) == "Satisfacción"? this.getNameEmoji(user_response.respuesta):user_response.respuesta,
              rut:rut_
            }
            data_.push(poll_data)
          });
        });
      }
      this.fields_excel = fields;
      this.data_excel = data_;
    },
    nameSeg(name){
      if(name == '1') return 'Gerencia';
      if(name == '2') return 'Lugar de trabajo';
      if(name == '3') return 'Sindicato';
      if(name == '4') return 'Genero';
      else return 'Transversal';
    },
    getNameEmoji(url){
      if (
          url ==
          "https://storage.googleapis.com/c3-bucket-01/iconos_encuestas/icono_wrong.png"
        ) {
          return "Enojado";
        }
      if (
        url ==
        "https://storage.googleapis.com/c3-bucket-01/iconos_encuestas/icono_doubt.png"
      ) {
        return "Dudoso";
      }
      if (
        url ==
        "https://storage.googleapis.com/c3-bucket-01/iconos_encuestas/icono_smiley.png"
      ) {
        return "Feliz";
      }
    },
    getCounteredAnswer(answers, questions){
    answers.forEach((ans) => {
        questions.forEach((ques) => {
          if (ans.idP == ques.idP && ans.textR == ques.textA) {
            ques.count++;
          }
        });
      });
    return questions;
  }
  },
 
};
</script>
